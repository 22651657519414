import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../layout/primary';
import { Box, Heading } from 'grommet';
import {
  Hero,
  AboutCarousel,
  Ram,
  ResponsiveContext,
  ContactForm,
  GenericComponent,
  AboutCta,
  AccordionBlock,
  CareersBlock,
} from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';

export const query = graphql`
  {
    prismic {
      content: allBetter_togethers {
        edges {
          node {
            meta_title
            meta_description
            hero_header
            hero_copy
            hero_image
            module_1 {
              image
              title
              text
              contact_email
              module_1_cta {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
            careers_background
            careers_image
            module_2_header
            module_2_copy
            module_2_cta {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            background_image
            slides {
              image
              quote
            }
            faq_module {
              faq_header
              faq_copy
            }
            contact_us {
              ... on PRISMIC_Contact {
                name
                text
                cta_button_1
                cta_button_2
                cta_link_1
                cta_link_2
                cta_title_1
                cta_title_2
              }
            }
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.content.edges[0].node;
  const slides = content.slides.slice(0, content.slides.length).map((item, index) => {
    const id = `testimonials-${index}`;
    return {
      id,
      authorQuote: item.quote,
      authorImage: item.image,
    };
  });
  const benefits = content.module_1.map((item, index) => {
    return {
      id: `benefits-${index}`,
      title: item.title,
      description: item.text,
      contactEmail: item.contact_email,
      image: item.image,
      document: item.module_1_cta,
    };
  });
  return (
    <Layout title={content.meta_title} description={content.meta_description}>
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <>
              <Hero
                isAbout
                imageMobile={content.hero_image.mobile.url}
                imageDesk={content.hero_image.url}
                heroHeader={content.hero_header}
                heroCopy={content.hero_copy}
                htmlSerializer={htmlSerializer}
              />
              <Box margin={{ top: '10rem' }}>
                <GenericComponent
                  size={size}
                  cardInformation={benefits}
                  htmlSerializer={htmlSerializer}
                />
              </Box>
              <CareersBlock
                Link={Link}
                bkgImage={content.careers_background.url}
                image={content.careers_image}
                eyebrowProps={{
                  color: 'accent-2',
                  text: '',
                }}
                titleProps={{
                  color: 'dark-1',
                  text: content.module_2_header,
                }}
                bodyProps={{
                  color: 'dark-1',
                  text: content.module_2_copy[0].text,
                }}
                ctaButtonProps={{
                  to: '/about',
                  text: 'Learn More About POWER',
                }}
              />
              <Box margin={{ vertical: '30px' }} align="center">
                <Heading level="3" size={size}>
                  Frequently Asked Questions
                </Heading>
                <AccordionBlock faq={content.faq_module} />
              </Box>
              <Ram color="E63D2F" />
              <AboutCarousel
                backgroundImage={content.background_image.url}
                carouselContent={slides}
              />
              <Box width="1440px" margin={{ vertical: '5rem', right: 'auto', left: 'auto' }}>
                <AboutCta
                  Link={Link}
                  ctaHeadingOne="Looking for new opportunities?"
                  ctaToOne="/careers"
                  ctaButtonOne="Explore Our Careers"
                  ctaHeadingTwo="Want to learn more about our capabilities?"
                  ctaToTwo="/services"
                  ctaButtonTwo="Explore Our Services"
                />
              </Box>
              <Box width="996px" margin="auto">
                <ContactForm
                  size={size}
                  heading="Contact Us"
                  body={content.contact_us.text}
                  cardOneHeading={content.contact_us.cta_title_1}
                  cardOneTo={content.contact_us.cta_link_1}
                  cardOneButtonLabel={content.contact_us.cta_button_1}
                  cardTwoHeading={content.contact_us.cta_title_2}
                  cardTwoTo={content.contact_us.cta_link_2}
                  cardTwoButtonLabel={content.contact_us.cta_button_2}
                  htmlSerializer={htmlSerializer}
                />
              </Box>
            </>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
